import { useLazyQuery } from '@apollo/client';
import { Checkbox, Col, Row, Tooltip } from 'antd';
import { filter, forEach, map, nth, slice } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import {
  BREAKPOINTS,
  DATETIMEWITHDIVIDE,
  DEFAULT_PAGE_SIZE
} from '../../../common/constants';
import { removeHistoryStateData } from '../../../common/utils';
import CommonCard from '../../../components/CommonCard';
import CommonDropdown from '../../../components/CommonDropdown';
import CommonTable from '../../../components/CommonTable';
import EllipsisText from '../../../components/EllipsisText';
import InfiniteScrollHandler from '../../../components/InfiniteScrollHandler';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import { GET_PROJECT_DROPDOWN_LIST } from '../../projects/graphql/Queries';
import { GET_USER_EQC_APPROVAL_LOGS } from '../graphql/Queries';
import ThankyouModal from './ThankyouModal';

const TodoList = () => {
  const initialTodoFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'updatedAt', order: 'DESC' },
    isRfi: false
  };

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [approvalListData, setApprovalListData] = useState();
  const [sortedInfo, setSortedInfo] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [todoListFilter, setTodoListFilter] = useState(initialTodoFilter);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const [isRfi, setIsRfi] = useState(todoListFilter?.isRfi);

  const [fetchTodoData, { loading }] = useLazyQuery(
    GET_USER_EQC_APPROVAL_LOGS,
    {
      variables: { filter: todoListFilter },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.getUserEqcApprovalLogs?.data;
        const pagination = {
          ...paginationProp,
          total: res?.getUserEqcApprovalLogs?.total
        };
        if (scrollFlag) {
          const datalist = [...approvalListData, ...data];
          setApprovalListData(datalist);
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setApprovalListData(datalist);
        }
        setHasMore(!!data?.length);
        setPaginationProp(pagination);
      },
      onError() {}
    }
  );

  useEffect(() => {
    if (history?.location?.state?.showThankyouModal) {
      setShowModal(true);
    }
  }, [history?.location?.state?.showThankyouModal]);

  useEffect(() => {
    fetchTodoData({ variables: { filter: todoListFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, filterData, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setTodoListFilter({
        ...todoListFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.columnKey,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
        }
      });
      fetchTodoData({
        variables: {
          filter: {
            ...todoListFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: {
              field: sorter.columnKey,
              order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
            }
          }
        }
      });
    } else {
      setTodoListFilter({
        ...todoListFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: { field: 'updatedAt', order: 'DESC' }
      });
      fetchTodoData({
        variables: {
          filter: {
            ...todoListFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: { field: 'updatedAt', order: 'DESC' }
          }
        }
      });
    }
  };

  const getStageNameAndStatus = (eqcStageHistory) => {
    const { name, levelName } = eqcStageHistory;
    return (
      <div>
        <span>{name || '-'}</span>
        <div>({levelName})</div>
      </div>
    );
  };

  const getApproverName = (values, record) => {
    const fetchName = [];
    const { eqcStageApprovals } = record?.eqcStage;
    forEach(
      filter(
        eqcStageApprovals,
        (data) => data?.levelNumber === record?.eqcStageHistory?.levelNumber
      ),
      (approver) => {
        const {
          externalApproverName: extApprName,
          internalApproverName: intApprName
        } = approver;
        if (intApprName || extApprName) {
          fetchName.push(intApprName || extApprName);
        }
      }
    );
    const selectedName = nth(fetchName, 0);
    if (fetchName?.length === 1) {
      return (
        <Tooltip title={selectedName} placement="right">
          {selectedName}
        </Tooltip>
      );
    }
    if (fetchName?.length > 1) {
      return (
        <Tooltip
          title={slice(fetchName, 1, fetchName?.length).join(', ')}
          placement="right"
        >
          {`${selectedName} and +${fetchName?.length - 1}`}
        </Tooltip>
      );
    }
    return '-';
  };

  const getNextStage = (record) => {
    if (
      !record?.eqcStageHistory?.inspectorReminderDate ||
      record?.eqcStageHistory?.isRfiStage
    ) {
      return '-';
    }
    const isOverDue =
      moment(record?.eqcStageHistory?.inspectorReminderDate) < moment();
    return (
      <div className={isOverDue ? 'text-danger' : ''}>
        {isOverDue ? `Due since` : `Due in`}
        {` ${moment(record?.eqcStageHistory?.inspectorReminderDate).from(
          moment(),
          true
        )}`}
        <div>
          {moment(record?.eqcStageHistory?.inspectorReminderDate).format(
            DATETIMEWITHDIVIDE
          )}
        </div>
      </div>
    );
  };
  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return <div>{todoListFilter?.skip + index + 1}</div>;
      }
    },
    {
      title: 'PROJECT NAME',
      dataIndex: 'projectName',
      key: 'projectName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'projectName' && sortedInfo?.order,
      render: (text, record) => (
        <div>
          <EllipsisText text={record?.eqc?.project?.name} />
        </div>
      )
    },
    {
      title: 'NAME',
      dataIndex: 'eqcName',
      key: 'eqcName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'eqcName' && sortedInfo?.order,
      render: (text, record) => (
        <div>
          <EllipsisText text={record?.eqc?.eqcName} />
        </div>
      )
    },
    {
      title: 'TYPE',
      dataIndex: 'projectEqcTypeName',
      key: 'projectEqcTypeName',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'projectEqcTypeName' && sortedInfo?.order,
      render: (text, record) => (
        <div>
          <EllipsisText text={record?.eqc?.projectEqcTypeName || '-'} />
        </div>
      )
    },
    {
      title: 'STAGE(Approval Pending)',
      key: 'eqcStageHistory',
      dataIndex: 'eqcStageHistory',
      render: (text) => {
        return getStageNameAndStatus(text);
      }
    },
    {
      title: 'APPROVERS',
      key: 'approver',
      dataIndex: 'approver',
      render: (values, record) => getApproverName(values, record)
    },
    {
      title: 'REQUESTED BY',
      key: 'approvalSender',
      dataIndex: 'approvalSender',
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'approvalSender' && sortedInfo?.order,
      render: (text, record) => record?.approvalSender?.name || '-'
    },
    {
      title: 'NEXT STAGE',
      key: 'nextStage',
      render: (_, record) => getNextStage(record)
    },
    {
      title: 'APPROVAL LINK',
      key: 'approvalUrl',
      dataIndex: 'approvalUrl',
      render: (text) => (
        <a href={text} rel="noreferrer">
          Review
        </a>
      )
    }
  ];

  const onSearchChange = async (value) => {
    setTodoListFilter({ ...todoListFilter, skip: 0, search: value });
    setPaginationProp(initialPaginationValue);
    fetchTodoData({
      variables: { filter: { ...todoListFilter, skip: 0, search: value } }
    });
  };
  const handleRefetch = () => {
    fetchTodoData({
      variables: {
        filter: {
          ...todoListFilter,
          skip: approvalListData?.length,
          limit: DEFAULT_PAGE_SIZE,
          sortBy: { field: 'createdAt', order: 'DESC' }
        }
      }
    });
  };
  const onProjectChange = (projectId) => {
    setTodoListFilter({
      ...todoListFilter,
      projectId
    });
    fetchTodoData({
      variables: {
        filter: {
          ...todoListFilter,
          projectId: Number(projectId)
        }
      }
    });
  };
  return (
    <>
      <Portal portalId="search-component">
        <div className={isDesktopViewport ? 'd-flex' : 'width-percent-100'}>
          <Row
            justify={isDesktopViewport ? 'end' : 'start'}
            className={`d-flex filter-search `}
            wrap
            gutter={isDesktopViewport ? [10, 10] : [0, 10]}
          >
            <Col
              span={isDesktopViewport ? null : 12}
              className="d-flex align-center"
            >
              <Checkbox
                onChange={(event) => {
                  setIsRfi(event?.target?.checked);
                  setTodoListFilter({
                    ...todoListFilter,
                    skip: 0,
                    isRfi: event?.target?.checked
                  });
                  setPaginationProp({ ...paginationProp, current: 1 });
                  fetchTodoData({
                    variables: {
                      filter: {
                        ...todoListFilter,
                        isRfi: event?.target?.checked
                      }
                    }
                  });
                }}
                checked={isRfi}
              >
                RFI
              </Checkbox>
            </Col>
            <Col span={isDesktopViewport ? null : 24}>
              <CommonDropdown
                allowClear
                placeholder="Project"
                className={`instruction-selector dropdown-width-auto ${
                  isDesktopViewport ? 'width-200' : 'width-percent-100'
                } `}
                onChange={onProjectChange}
                query={GET_PROJECT_DROPDOWN_LIST}
                fetchPolicy="network-only"
                responsePath="projectDropdownList.data"
                valuePath="id"
                labelPath="name"
                optionKey="project"
                showSearch
                optionFilterProp="children"
                dropdownMatchSelectWidth={false}
              />
            </Col>
            <Col span={isDesktopViewport ? null : 24}>
              <SearchComponent
                id="search-container-id"
                getData={onSearchChange}
              />
            </Col>
          </Row>
        </div>
      </Portal>
      {showModal && (
        <ThankyouModal
          setShowModal={setShowModal}
          showModal={showModal}
          loading={loading}
          count={paginationProp?.total}
          handleClick={() => {
            removeHistoryStateData(
              history,
              history?.location,
              'showThankyouModal'
            );
          }}
        />
      )}
      {isDesktopViewport ? (
        <CommonTable
          loadingData={loading}
          columns={columns}
          data={approvalListData || []}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey={(obj) => obj?.id}
        />
      ) : (
        <InfiniteScrollHandler
          scrollFlag={scrollFlag}
          loading={loading}
          refetchData={handleRefetch}
          setScrollFlag={setScrollFlag}
          hasMore={hasMore}
          dataLength={approvalListData?.length}
          skeletonRows={columns?.length - 2}
        >
          {map(approvalListData, (todo, index) => {
            return (
              <CommonCard key={todo?.id}>
                <div className="common-card d-flex">
                  <div className="mr-5 fw-medium">{index + 1}.</div>
                  <div>
                    <div className="card-header fw-medium">
                      <span>{todo?.eqc?.project?.name}</span>
                    </div>
                    <div className="card-content text-secondary">
                      <br />
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Name:</span>
                        {todo?.eqc?.eqcName}
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Type:</span>
                        {todo?.eqc?.projectEqcTypeName || '-'}
                      </div>
                      <div className="d-flex mb-15">
                        <span className="fw-medium mr-5">
                          Stage(Approval Pending):
                        </span>
                        {getStageNameAndStatus(todo?.eqcStageHistory) || '-'}
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Approver:</span>
                        {getApproverName(approvalListData, todo)}
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Requested by:</span>
                        {todo?.approvalSender?.name || '-'}
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Next stage:</span>
                        {getNextStage(todo)}
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Approval link:</span>
                        <a
                          href={todo?.approvalUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Review
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </CommonCard>
            );
          })}
        </InfiniteScrollHandler>
      )}
    </>
  );
};

export default TodoList;
