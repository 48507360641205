import { Button, Card, Descriptions, Tag } from 'antd';
import clsx from 'clsx';
import { nth } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import {
  BREAKPOINTS,
  DATETIMEWITHDIVIDE,
  EQC_STATUS,
  EQC_STATUS_KEYS,
  ROUTES,
  TAB_KEYS
} from '../../common/constants';
import { titleCase } from '../../common/utils';
import EllipsisText from '../../components/EllipsisText';

const EqcSummary = ({ data, loading }) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const history = useHistory();
  const eqcStatus = (status) => {
    if (status === EQC_STATUS_KEYS.PASSED) {
      return <Tag className="active-tag">{titleCase(EQC_STATUS.PASSED)}</Tag>;
    }
    if (status === EQC_STATUS_KEYS.IN_PROGRESS) {
      return (
        <Tag className="yellow-tag">{titleCase(EQC_STATUS.IN_PROGRESS)}</Tag>
      );
    }
    return <Tag color="red">{titleCase(EQC_STATUS.TERMINATE)}</Tag>;
  };
  const getNextStage = (reminderDate) => {
    if (
      !reminderDate ||
      data?.getEqcStageHistoryWithoutAuth?.eqc?.status ===
        EQC_STATUS_KEYS.PASSED
    ) {
      return '-';
    }
    const isOverDue = moment(reminderDate) < moment();
    return (
      <div className={isOverDue ? 'text-danger' : ''}>
        {isOverDue ? `Due since` : `Due in`}
        {` ${moment(reminderDate).from(moment(), true)}`}
        <div>{moment(reminderDate).format(DATETIMEWITHDIVIDE)}</div>
      </div>
    );
  };
  return (
    <div className="eqc-summary">
      <div
        className={
          isDesktopViewport
            ? 'd-flex justify-between align-center'
            : 'd-flex flex-vertical'
        }
      >
        <Card loading={loading} className="eqc-summary-card">
          <Descriptions
            column={isDesktopViewport ? 8 : 2}
            layout="vertical"
            colon={false}
          >
            <Descriptions.Item
              label="Name"
              className={clsx(!isDesktopViewport && 'common-item')}
              span={2}
            >
              <EllipsisText
                text={data?.getEqcStageHistoryWithoutAuth?.eqc?.eqcName}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Checklist">
              <EllipsisText
                text={
                  data?.getEqcStageHistoryWithoutAuth?.eqc?.projectEqcTypeName
                }
              />
            </Descriptions.Item>
            <Descriptions.Item
              label="Uom"
              className={clsx(!isDesktopViewport && 'common-item')}
            >
              {data?.getEqcStageHistoryWithoutAuth?.eqc?.uom}
            </Descriptions.Item>
            <Descriptions.Item label="Eqc Status">
              {eqcStatus(data?.getEqcStageHistoryWithoutAuth?.eqc?.status)}
            </Descriptions.Item>
            <Descriptions.Item label="Revision">
              {
                nth(data?.getEqcStageHistoryWithoutAuth?.eqcApprovalLogs, 0)
                  ?.revision
              }
            </Descriptions.Item>
            <Descriptions.Item label="Next Stage" span={2}>
              {data?.getEqcStageHistoryWithoutAuth?.isRfiStage
                ? '-'
                : getNextStage(
                    data?.getEqcStageHistoryWithoutAuth?.inspectorReminderDate
                  )}
            </Descriptions.Item>
          </Descriptions>
        </Card>
        {data?.getEqcStageHistoryWithoutAuth?.projectId && (
          <Button
            type="primary"
            shape="round"
            className={`${
              !isDesktopViewport
                ? 'width-percent-100 justify-center mt-20 mb-24'
                : 'mb-24'
            }`}
            onClick={() =>
              history.push(
                `${ROUTES.PROJECTS}/${data?.getEqcStageHistoryWithoutAuth?.projectId}/${TAB_KEYS.EQC}/${data?.getEqcStageHistoryWithoutAuth?.eqc?.id}`
              )
            }
          >
            EQC History
          </Button>
        )}
      </div>
    </div>
  );
};

export default EqcSummary;
